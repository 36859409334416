/**
 * Do not edit directly
 * Generated on Thu, 18 Jul 2024 16:52:43 GMT
 */

:root {
  --base-colors-prime-surface: #f8f9fa;
  --base-colors-error-highlight: #e24c4c;
  --base-colors-text-light: #495057;
  --nav-container-border: #D8D8D8;
  --telus-gray: #2A2C2E;
  --telus-input-highlight: #FDE8BA;
  --telus-orange-attention: #F4A836;
  --telus-red-negative: #F44336;
  --telus-alabaster: #FAFAFA;
  --telus-mystic: #F4F4F7;
  --telus-cloud: #B2B9BF;
  --telus-shuttle: #676E73;
  --telus-charcoal: #414547;
  --telus-thunder: #2C2E30;
  --telus-accessible-lighter-green: #A2C78F;
  --telus-accessible-light-green: #D5E6CC;
  --telus-purple-hover: #341c4c;
  --telus-background-grey: #F4F4F7;
  --telus-black: #000000;
  --red-900: #661814;
  --red-800: #8c221c;
  --red-700: #b32b23;
  --red-600: #d9342b;
  --red-500: #9C230A;
  --red-400: #D73111;
  --red-300: #F53611;
  --red-200: #E4C9C1;
  --red-100: #FFF0EC;
  --red-50: #fff5f5;
  --purple-900: #4B286D;
  --purple-800: #5c2f88;
  --purple-700: #763cad;
  --purple-600: #8f48d2;
  --purple-500: #210542;
  --purple-400: #4B286D;
  --purple-300: #65299E;
  --purple-200: #D6C4E6;
  --purple-100: #F8F0FF;
  --purple-50: #fbf7ff;
  --bluegray-900: #282e38;
  --bluegray-800: #37404c;
  --bluegray-700: #465161;
  --bluegray-600: #556376;
  --bluegray-500: #64748b;
  --bluegray-400: #818ea1;
  --bluegray-300: #9fa9b7;
  --bluegray-200: #bcc3cd;
  --bluegray-100: #dadee3;
  --bluegray-50: #f7f8f9;
  --orange-900: #642e09;
  --orange-800: #893f0c;
  --orange-700: #ae510f;
  --orange-600: #d46213;
  --orange-500: #AD4511;
  --orange-400: #E0660E;
  --orange-300: #FF6B00;
  --orange-200: #E1C9BA;
  --orange-100: #FFF4ED;
  --orange-50: #fff8f3;
  --teal-900: #084a42;
  --teal-800: #0b655b;
  --teal-700: #0e8174;
  --teal-600: #119c8d;
  --teal-500: #14b8a6;
  --teal-400: #41c5b7;
  --teal-300: #6dd3c8;
  --teal-200: #9ae0d9;
  --teal-100: #c7eeea;
  --teal-50: #f3fbfb;
  --indigo-900: #282960;
  --indigo-800: #363885;
  --indigo-700: #4547a9;
  --indigo-600: #5457cd;
  --indigo-500: #6366f1;
  --indigo-400: #8183f4;
  --indigo-300: #9ea0f6;
  --indigo-200: #bcbdf9;
  --indigo-100: #dadafc;
  --indigo-50: #f7f7fe;
  --pink-900: #5e1d3d;
  --pink-800: #822854;
  --pink-700: #a5326b;
  --pink-600: #c93d82;
  --pink-500: #ec4899;
  --pink-400: #f06bac;
  --pink-300: #f38ec0;
  --pink-200: #f7b0d3;
  --pink-100: #fad3e7;
  --pink-50: #fef6fa;
  --cyan-900: #024955;
  --cyan-800: #036475;
  --cyan-700: #047f94;
  --cyan-600: #059bb4;
  --cyan-500: #06b6d4;
  --cyan-400: #35c4dc;
  --cyan-300: #65d2e4;
  --cyan-200: #94e0ed;
  --cyan-100: #c3edf5;
  --cyan-50: #f3fbfd;
  --yellow-900: #5e4803;
  --yellow-800: #816204;
  --yellow-700: #a47d06;
  --yellow-600: #c79807;
  --yellow-500: #eab308;
  --yellow-400: #eec137;
  --yellow-300: #FFB800;
  --yellow-200: #f6de95;
  --yellow-100: #faedc4;
  --yellow-50: #fefbf3;
  --green-900: #0e4f26;
  --green-800: #136c34;
  --green-700: #188a42;
  --green-600: #1da750;
  --green-500: #174300;
  --green-400: #216300;
  --green-300: #2B8000;
  --green-200: #CFE6C1;
  --green-100: #F5FFEF;
  --green-50: #f4fcf7;
  --blue-900: #183462;
  --blue-800: #204887;
  --blue-700: #295bac;
  --blue-600: #326fd1;
  --blue-500: #3b82f6;
  --blue-400: #609af8;
  --blue-300: #85b2f9;
  --blue-200: #abc9fb;
  --blue-100: #d0e1fd;
  --blue-50: #f5f9ff;
  --white-100: #FFFFFF;
  --dark-100: #2A2B30;
  --gray-900: #212121;
  --gray-800: #424242;
  --gray-700: #616161;
  --gray-600: #565A5F;
  --gray-500: #81878C;
  --gray-400: #9EA3A7;
  --gray-300: #BFC3C5;
  --gray-200: #DADCE0;
  --gray-100: #EEF0F2;
  --gray-50: #FAFAFA;
}
