@import './_variables';

@font-face {
  font-family: 'HN for TELUS';
  src: url('#{$font-base-path}/fonts/HNforTELUS.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HN for TELUS';
  src: url('#{$font-base-path}/fonts/HNforTELUS-Md.woff2') format('woff2');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HN for TELUS';
  src: url('#{$font-base-path}/fonts/HNforTELUS-Bd.woff2') format('woff2');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'HN for TELUS';
  src: url('#{$font-base-path}/fonts/HNforTELUS-It.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'HN for TELUS';
  src: url('#{$font-base-path}/fonts/HNforTELUS-MdIt.woff2') format('woff2');
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HN for TELUS';
  src: url('#{$font-base-path}/fonts/HNforTELUS-BdIt.woff2') format('woff2');
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'HN for TELUS Display';
  src: url('#{$font-base-path}/fonts/HNforTELUS-Display.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HN for TELUS Display';
  src: url('#{$font-base-path}/fonts/HNforTELUS-DisplayMd.woff2') format('woff2');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HN for TELUS Display';
  src: url('#{$font-base-path}/fonts/HNforTELUS-DisplayBd.woff2') format('woff2');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'HN for TELUS Display';
  src: url('#{$font-base-path}/fonts/HNforTELUS-DisplayIt.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'HN for TELUS Display';
  src: url('#{$font-base-path}/fonts/HNforTELUS-DisplayMdIt.woff2') format('woff2');
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HN for TELUS Display';
  src: url('#{$font-base-path}/fonts/HNforTELUS-DisplayBdIt.woff2') format('woff2');
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'HN for TELUS Micro';
  src: url('#{$font-base-path}/fonts/HNforTELUS-Micro.woff2') format('woff2');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'HN for TELUS Micro';
  src: url('#{$font-base-path}/fonts/HNforTELUS-MicroMd.woff2') format('woff2');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: 'HN for TELUS', sans-serif;
}