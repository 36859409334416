@import "../../core/fonts";
@import './primeng-light';

:root {
    /* Telus colors */
    --white: #FFFFFF;
    --background-grey: #F4F4F7;
    --telus-purple: #4B286D;
    --telus-green: #66CC00;
    --accessible-green: #2B8000;

    /* Telus extended grey*/

    --thunder: #2C2E30;
    --charcoal: #414547;
    --shuttle: #676E73;
    --cloud: #B2B9BF;
    --mystic: #F4F4F7;
    --alabaster: #FAFAFA;

    /* Accessible green */
    --accessible-green-50: #E6F0E0;
    --accessible-green-100: #BFD9B3;
    --accessible-green-200: #95C080;
    --accessible-green-300: #6BA64D;
    --accessible-green-400: #4B9326;
    --accessible-green-500: #2B8000;
    --accessible-green-600: #267800;
    --accessible-green-700: #206D00;
    --accessible-green-800: #1A6300;
    --accessible-green-900: #105000;

    /* Telus Purple */
    --telus-purple-50: #E9E5ED;
    --telus-purple-100: #C9BFD3;
    --telus-purple-200: #A594B6;
    --telus-purple-300: #816999;
    --telus-purple-400: #664883;
    --telus-purple-500: #4B286D;
    --telus-purple-600: #442465;
    --telus-purple-700: #3B1E5A;
    --telus-purple-800: #331850;
    --telus-purple-900: #230F3E;

    /* Telus Green */
    --telus-green-50: #EDF9E0;
    --telus-green-100: #D1F0B3;
    --telus-green-200: #B3E680;
    --telus-green-300: #94DB4D;
    --telus-green-400: #7DD426;
    --telus-green-500: #66CC00;
    --telus-green-600: #5EC700;
    --telus-green-700: #53C000;
    --telus-green-800: #49B900;
    --telus-green-900: #38AD00;

    /* Custom Red */
    --custom-red-50: #FEE8E7;
    --custom-red-100: #FCC7C3;
    --custom-red-200: #FAA19B;
    --custom-red-300: #F77B72;
    --custom-red-400: #F65F54;
    --custom-red-500: #F44336;
    --custom-red-600: #F33D30;
    --custom-red-700: #F13429;
    --custom-red-800: #EF2C22;
    --custom-red-900: #EC1E16;

    /*Custom Orange */
    --custom-orange-attention: #F4A836;

    /* Gradients */

    --telus-purple-gradient: linear-gradient(106.22deg, #4B286D 50.46%, #E53293 100%);
    --telus-green-gradient: linear-gradient(106.22deg, #00804A 50.46%, #66CC00 100%);

}
